body {
    background-image: linear-gradient(rgba(0, 0, 0, 0.3),
    rgba(0, 0, 0, 0.3)), url('./assets/computer.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    background-position: center;

    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    padding: 20px 60px;
}  

a {
    text-decoration: none;
    color: white;
    font-size: 20px;
    text-shadow: 1px 1px 0px black;    
}

p {
    font-size: 20px;
}
    
* {
    box-sizing: border-box;
    color: white;
}

    



@mixin shrinkLabel {
    top: -10px;
    font-size: 18px;
    color: white;
}

.group {
    position: relative;
    margin: 30px 0;

    .form-input {
        display: block;
        font-size: 18px;
        width: 100%;
        color: white;
        background: none;
        padding: 10px 10px 10px 5px;
        border: none;
        border-radius: 0;
        border-bottom: 1px solid white;
        margin: 1px 0;
        
        &:focus {
            outline: none;
        }

        &:focus ~ .form-input-label {
            @include shrinkLabel();
        }           
    }

    .form-textarea {
        font-size: 18px;
        width: 100%;
        height: 100px;
        color: white;
        background: none;
        padding: 10px 10px 10px 5px;
        border: none;
        border-radius: 10px;
        border: 1px solid white;
        margin: 20px 0;

        &:focus {
            outline: none;
        }

        &:focus ~ .form-input-label {
            @include shrinkLabel();
        }

    }

    .form-input-label {
        color: white;
        font-size: 18px;
        font-weight: normal;
        position:  absolute;
        pointer-events: none;
        left: 5px;
        top: 20px;
        transition: 300ms ease all;

        &.shrink {
            @include shrinkLabel();
        }
    }

    .custom-button {
        width: 35vw;
    }
}
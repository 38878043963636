.contact {
    width: 100%;
    display: flex;
    flex-direction: column;

    .success {
        margin-top: 20px;
        padding:20px;
        border-radius: 20px;
        width: 100%;
        display: flex;
        flex-direction: column;
        text-align: center;
        background: rgb(58, 134, 58);
    }

    .fail {
        margin-top: 20px;
        padding:20px;
        border-radius: 20px;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-self: center;
        background: rgb(204, 40, 40);
    }

    .form-container {
        margin-top: 20px;
        padding:20px;
        border-radius: 20px;
        width: fit-content;
        display: flex;
        flex-direction: column;
        align-self: center;
        background-image: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0,0,0,0.3));


        .custom-button {
            width: 35vw;
        }
    }
}
.project-page {
    width: 100%;
    height: 100%;
    margin-top: 10px;
    display: flex;
    flex-flow: wrap;
    justify-content: center;
    gap:12px;
}


.header {
    height: 70px;
    width: 100%;
    display: flex;
    justify-content: flex-end;

    .options {
        width: auto;
        height: 100%;
        display: flex;
        align-items: center;
        border-bottom: 1px solid white;

        .option {
            margin: 5px;
            padding: 12px;
            cursor: pointer;
            &:hover {
                text-decoration: underline lightblue;
            }
        }
    }
}
    



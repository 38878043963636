.project-card {
    height: auto;
    width: 300px;
    display: flex;
    padding: 20px;
    flex-direction: column;
    justify-content: space-between;
    border: 1px solid white;
    border-radius: 20px;
    transition: 500ms ease-in;
    background-image: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3));

    span {
        margin-bottom: 10px;
    }

    .title {
        align-self: center;
        text-decoration: underline;
    }

    .custom-button {
        align-self: center;
    }

    .project-links {
        height: 100%;
        width: 100%;
        display: flex;
        flex-flow: column;
        justify-content: center;
        gap: 10px;
    }

    &:hover {
        box-shadow:  0 4px 8px 0 rgba(0, 0, 0, 0.5), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        transition: 500ms ease-in ;
    }
}
.custom-button {
    height: 30px;
    width: auto;
    letter-spacing: 0.5px;
    padding: 15px;
    border: none;
    background-color: lightblue;
    border-radius: 20px;
    color: black;
    text-transform: capitalize;
    font-weight: bolder;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    text-shadow: none;
    transition: 500ms ease-out;

    &:hover {
        background: rgb(99, 155,238);
    }
}

.wire-frame {
    @extend custom-button !optional;
    color: white;
    border: 1px solid white;
    background: none;
    border-radius: 0px;
    text-shadow: .5px .5px 3px black;

    &:hover {
        transform: scale(1.2);
        transition: 500ms ease-in;
        text-shadow: none;
        
    }
}
